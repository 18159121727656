import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Mission from './components/Mission';
import Gallery from './components/Gallery';
import ContactForm from './components/Contact';
import Footer from './components/Footer';
import { Header, Hero } from './components/Hero';
import ScrollingGallery from './components/ScrollingGallery';
import AboutMe from './components/About';
import Features from './components/Icons';
import SocialMedia from './components/SocialMedia';
import Login from './components/admin/Login';
import Dashboard from './components/admin/Dashboard';
import ProtectedRoute from './components/admin/ProtectedRoute';

const MainContent = () => (
  <main>
    <Hero />
    <Features />
    <Mission />
    <AboutMe />
    <ScrollingGallery />
    <SocialMedia />
    <ContactForm />
  </main>
);

const App = () => {
  return (
    <Router>
      <div className="min-h-screen">
        <Routes>
          <Route path="/admin" element={<Login />} />
          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <>
                <MainContent />
                <Footer />
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;